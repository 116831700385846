// Customizable Area Start
import React from "react"
import {
    Box,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    styled,
    MenuItem, Badge, Menu, Button, Backdrop, Fade, Modal, ClickAwayListener, IconButton,
} from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { setStorageData, removeStorageData, getStorageData } from "../../framework/src/Utilities";
import { shopping, faceLogo, search } from "../../blocks/email-account-registration/src/assets";
import { admin, logout, usericon } from "../../blocks/customisableuserprofiles2/src/assets";
import AuthenticateUser from "../src/AuthenticateUser";
import i18n from "./i18next/i18n";
import { notification, notificationRead } from "./Seller/assets";
import { AppContext } from "./AlertContainer";
const frame = require('../../components/src/frame.svg');
const faq = require('../../components/src/image_Icon.svg');

interface S {
    openMenu: boolean;
    anchorEl: HTMLElement | null;
    open1: boolean,
    numberOrder: number | null;
    auth: string;
    modalOpen: boolean;
    buyerModal: boolean;
    searchBox: boolean;
    showNotification: boolean;
    headerText: string | null | undefined;
}
export interface Props {
    navigation?: any;
    id?: string;
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Header extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            openMenu: false,
            anchorEl: null,
            open1: false,
            numberOrder: 0,
            auth: "",
            modalOpen: false,
            buyerModal: false,
            searchBox: false,
            showNotification: false,
            headerText: this.translate("TopTitle")
        };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        // Customizable Area End
    }

    headerRedirect = (block: string) => {
        this.props.navigation.navigate(block)
    };
    headerNavigation = async (pageLink: string, name?: string) => {
        await setStorageData("navigationGender", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        if(pageLink==="CategoriessubcategoriesWebPage"){
            const raiseMessage: Message = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
              );
              raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage),  {gender: name})
              toNavigate.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        }
        this.send(toNavigate);
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
        document.addEventListener('mousedown', this.handleDocumentClick);
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
        document.removeEventListener('mousedown', this.handleDocumentClick);
    };

    handleClose1 = () => {
        this.setState({ open1: false });
    };
    logout = async () => {
        ["storedPath", "orderNumber", "fcm_token", "auth-token", "sms-token", "userRole", "stylitOrder", "matchedClients", "user_data", "resend_otp_number", "sms_otp_token", "unique_token", "user_id", "account_id"].map(async value => {
            await removeStorageData(value)
        })
        this.setState({ open1: false });
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), 'Home');
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    handleDocumentClick(event: MouseEvent) {
        if (this.state.anchorEl && !this.state.anchorEl.contains(event.target as Node)) {
            this.handleClose();
        }
    }
    handleOpenLogoutModel = () => {
        this.setState({ open1: true, anchorEl: null });
    };
    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }
    async componentDidMount() {
        const authtokenData = await getStorageData('auth-token');
        const headerText = await getStorageData("headerText");
        this.setState({ auth: authtokenData, headerText: headerText ?? this.translate("TopTitle") })
        if (authtokenData) { this.setState({ showNotification: true }) };
        const orderNumber = await getStorageData('orderNumber')
        if (orderNumber !== null || orderNumber !== "0") {
            this.setState({ numberOrder: orderNumber });
        } else {
            await removeStorageData("orderNumber");
        }
        const windowLocation = window.location.href?.split("/");
        if (windowLocation?.length === 4 && windowLocation[3] === "ProductSearch") {
            this.setState({ searchBox: true })
        }
    }
    async componentDidUpdate() {
        const orderNumber = await getStorageData('orderNumber');
        if (orderNumber !== this.state.numberOrder) {
            if (orderNumber === null || orderNumber === "0") {
                await removeStorageData("orderNumber");
                this.setState({ numberOrder: null });
            } else {
                this.setState({ numberOrder: orderNumber });
            }
        }

        const headerText = await getStorageData("headerText");
        if(headerText && headerText !== this.state.headerText) {
            this.setState({ headerText: headerText})
        }
    }


    renderDialog = () => {
        return (
            <AuthenticateUser
                auth={this.state.auth}
                title={this.translate("Log in to view stylists.")}
                description={this.translate("View list of stylists")}
                btnText={this.translate("Log In or Sign up")}
                navigation={this.props.navigation}
                onClose={this.closeModal}
            />
        )
    }

    renderBuyerDialog = () => {
        return (
            <AuthenticateUser
                auth={this.state.auth}
                title={this.translate("Log in to view Stores.")}
                description={this.translate("View list of Stores")}
                btnText={this.translate("Log In or Sign up")}
                navigation={this.props.navigation}
                onClose={() => {
                    this.setState({
                        buyerModal: false
                    })
                }}
            />
        )
    }

    handleHeaderButtonClick = () => {
        if (this.state.auth === null) {
            this.setState({ modalOpen: true })
        } else {
            this.headerNavigation("HiredStylist")
        }
    }

    handleHeaderButtonClickStore = () => {
        if (this.state.auth === null) {
            this.setState({ buyerModal: true })
        } else {
            this.headerNavigation("BuyerStore")
        }
    }

    handleHeaderSearch = () => {
        if (this.state.auth === null) {
            this.setState({ buyerModal: true })
        } else {
            this.headerNavigation("ProductSearch")
        }
    }

    closeModal = () => {
        this.setState({ modalOpen: false });
    };


    translate = (key: string) => {
        return i18n.t(key, { ns: "header" })
    }

    headerSignInRedirect = async(key: string) => {
        await setStorageData('storedPath', document.location.href)
        this.headerRedirect(key)
    }

    render() {
        // Customizable Area Start
        return (
            <>
                <StyledWrapper>
                    <Grid className="headerMainGrid" container dir={i18n.dir()}>
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="topContent"
                        >
                            <Typography
                                align="center"
                                style={{ fontWeight: 400 }}
                                className="topContentText"
                            >
                                {this.state.headerText}
                            </Typography>
                        </Grid>
                        {window.innerWidth >= 951 && (
                            <>
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={2}
                                    sm={4}
                                    xs={5}
                                    className="logoSection"
                                >
                                    <MenuRoundedIcon
                                        className="menu"
                                        onClick={() => {
                                            this.setState({ openMenu: !this.state.openMenu });
                                        }}
                                    />

                                    {this.state.openMenu && (
                                        <Paper className="menuItems" style={{ right: i18n.dir() === "rtl" ? "0" : "unset", left: i18n.dir() === "rtl" ? "unset" : "0" }}>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerRedirect("LandingPage") }}
                                            >
                                                {this.translate("Home")}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation("CategoriessubcategoriesWebPage", "Men") }}
                                            >
                                                {this.translate("Men")}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation("CategoriessubcategoriesWebPage", "Women") }}
                                            >
                                                {this.translate("Women")}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation("CategoriessubcategoriesWebPage", "Kids") }}
                                            >
                                                {this.translate("Kids")}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => this.headerNavigation("BuyerStore")}
                                            >
                                                {this.translate("Stores")}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={this.handleHeaderButtonClick}
                                            >
                                                {this.translate("Stylist")}
                                            </Typography>
                                        </Paper>
                                    )}
                                    <Box onClick={() => {
                                        this.headerRedirect("LandingPage")
                                    }}>
                                        <img src={faceLogo} alt="fashLogo" className="logo" />
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={5}
                                    sm={4}
                                    xs={3}
                                    className="linkSection"
                                    dir={i18n.dir()}
                                >
                                    <Typography
                                        className="link"
                                        onClick={() => {
                                            this.headerRedirect("LandingPage")
                                        }}
                                    >
                                        {this.translate("Home")}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={() => {
                                            this.headerNavigation("CategoriessubcategoriesWebPage", "Men")
                                        }}
                                    >
                                        {this.translate("Men")}
                                    </Typography>
                                    <Typography className="link" onClick={() => {
                                        this.headerNavigation("CategoriessubcategoriesWebPage", "Women")
                                    }}>
                                        {this.translate("Women")}
                                    </Typography>
                                    <Typography className="link" onClick={() => {
                                        this.headerNavigation("CategoriessubcategoriesWebPage", "Kids")
                                    }}>
                                        {this.translate("Kids")}
                                    </Typography>
                                    <Typography className="link" onClick={() => this.headerNavigation("BuyerStore")} >
                                        {this.translate("Stores")}
                                    </Typography>
                                    <Typography className="link" onClick={this.handleHeaderButtonClick}>
                                        {this.translate("Stylist")}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder={this.translate("Search")}
                                        className="searchBoxTab"
                                        dir={i18n.dir()}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={search} />
                                                </InputAdornment>
                                            ),
                                            // style: {
                                            //     display: "flex",
                                            //     maxWidth: "270px",
                                            //     height: "40px",
                                            //     justifyContent: "flex-start",
                                            //     borderRadius: "2px",
                                            //     border: "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
                                            // },
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={5}
                                    sm={4}
                                    xs={4}
                                    className="profileSection"
                                >
                                    {!this.state.searchBox && (
                                        <TextField
                                            variant="outlined"
                                            placeholder={this.translate("Search")}
                                            className="searchBox"
                                            dir={i18n.dir()}
                                            onClick={() => this.headerNavigation("ProductSearch")}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={search} className="searchBoxIcon" />
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    display: "flex",
                                                    maxWidth: "170px",
                                                    height: "40px",
                                                    justifyContent: "flex-start",
                                                    borderRadius: "2px",
                                                },
                                            }}
                                        />
                                    )}
                                    {/* <SearchSharpIcon className="searchIcon" /> */}
                                    {this.state.showNotification && <AppContext.Consumer>
                                        {(context: any) => (
                                            <CustomBadge showZero onClick={() => { context.closeIndicator(), this.headerRedirect("Notifications") }}>
                                                <img style={{cursor: "pointer"}} height="68px" src={!!context.newNoti ? notification : notificationRead} alt="notifications" />
                                            </CustomBadge>
                                        )}
                                    </AppContext.Consumer>}
                                    <CustomBadge badgeContent={this.state.numberOrder} showZero onClick={() => this.headerRedirect("ProductdetailsWeb")}>
                                        <img style={{cursor: "pointer"}} src={shopping} alt="shopping" />
                                    </CustomBadge>
                                    <div>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <Box aria-controls="simple-menu" className="profileBox" aria-haspopup="true" onClick={this.handleClick}>
                                                <IconButton onClick={this.handleClick} aria-controls="simple-menu" aria-haspopup="true">
                                                    <AccountCircleOutlinedIcon style={{ color: "#375280" }} />
                                                    <ExpandMoreOutlinedIcon style={{ color: "#375280" }} />
                                                </IconButton>

                                                <Custommenu
                                                    style={{ marginTop: '50px', }}
                                                    id="simple-menu"
                                                    keepMounted
                                                    anchorEl={this.state.anchorEl}
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={this.handleClose}
                                                    dir={i18n.dir()}
                                                >
                                                    <Box style={{ padding: '5px 15px' }} dir={i18n.dir()}>
                                                        {this.state.auth === null ? (
                                                            <>
                                                                <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("EmailAccountRegistration") }}> <img src={usericon} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />Sign Up</MenuItem>
                                                                <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerSignInRedirect("EmailAccountLoginBlock") }}> <img src={admin} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />Sign In</MenuItem>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Customisableuserprofiles2") }}> <img src={usericon} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />My Account</MenuItem>
                                                                <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Contactus") }}> <img src={frame} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />Contact Us</MenuItem>
                                                                <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Faqs") }}> <img src={faq} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />FAQs</MenuItem>
                                                                <MenuItem className="itemtextlogout" dir={i18n.dir()} onClick={this.handleOpenLogoutModel}>{this.state.auth === null ? "" : <img src={logout} style={{ marginRight: i18n.dir() === 'rtl' ? '0' : '10px', marginLeft: i18n.dir() === 'rtl' ? '10px' : '0' }} />}{this.state.auth === null ? "Sign In" : "Logout"}</MenuItem>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Custommenu>
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    <StyledDrawer
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={this.state.open1}
                                        onClose={this.handleClose1}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}
                                        dir={i18n.dir()}
                                    >
                                        <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                                            <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                                                <Box id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 900, marginLeft: "10px" }}>{this.state.auth === null ? "" : this.translate("Log Out")}</Box>
                                                <h2 id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px" }}>{this.state.auth === null ? this.translate("accessProfile") : this.translate("accessProfile")}</h2>
                                                <div style={{ marginTop: "15px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                                    <StyledButton style={{ fontSize: "18px", lineHeight: "20px" }} onClick={() => this.logout()}>{this.translate("Log Out")}</StyledButton>
                                                    <StyledButton1 style={{ fontSize: "18px" }} onClick={this.handleClose1}>{this.translate("Cancel")}</StyledButton1>
                                                </div>
                                            </div>
                                        </Fade>
                                    </StyledDrawer>
                                </Grid>
                            </>
                        )
                        }
                        {window.innerWidth < 951 && (
                            <>
                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={2}
                                    sm={4}
                                    xs={5}
                                    className="logoSection"
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                    <MenuRoundedIcon
                                        className="menu"
                                        onClick={() => {
                                            this.setState({ openMenu: !this.state.openMenu });
                                        }}
                                        style={{ fontSize: '30px', color: '#375280' }}
                                    />
                                    {this.state.openMenu && (
                                        <Paper
                                            className="menuItems"
                                            style={{
                                                position: 'absolute',
                                                top: '60px',
                                                right: i18n.dir() === 'rtl' ? '0' : 'unset',
                                                left: i18n.dir() === 'rtl' ? 'unset' : '0',
                                                background: 'white',
                                                borderRadius: '8px',
                                                boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                                                padding: '12px',
                                            }}
                                        >
                                            <Box onClick={() => {
                                                this.headerRedirect('LandingPage')
                                            }}>
                                                <img src={faceLogo} alt="fashLogo" className="logo" style={{ maxWidth: '100px' }} />
                                            </Box>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerRedirect('LandingPage') }}
                                                style={{ fontSize: '14px', marginBottom: '8px' }}
                                            >
                                                {this.translate('Home')}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Men') }}
                                                style={{ fontSize: '14px', marginBottom: '8px' }}
                                            >
                                                {this.translate('Men')}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Women') }}
                                                style={{ fontSize: '14px', marginBottom: '8px' }}
                                            >
                                                {this.translate('Women')}
                                            </Typography>
                                            
                                            <Typography
                                                className="menuLink"
                                                onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Kids') }}
                                                style={{ fontSize: '14px', marginBottom: '8px' }}
                                            >
                                                {this.translate('Kids')}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={() => this.headerNavigation('BuyerStore')}
                                                style={{ fontSize: '14px', marginBottom: '8px' }}
                                            >
                                                {this.translate('Stores')}
                                            </Typography>
                                            <Typography
                                                className="menuLink"
                                                onClick={this.handleHeaderButtonClick}
                                                style={{ fontSize: '14px' }}
                                            >
                                                {this.translate('Stylist')}
                                            </Typography>
                                        </Paper>
                                    )}

                                </Grid>

                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={5}
                                    sm={4}
                                    xs={3}
                                    className="linkSection"
                                    dir={i18n.dir()}
                                    style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start' }}
                                >

                                    <Typography
                                        className="link"
                                        onClick={() => { this.headerRedirect('LandingPage') }}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Home')}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Men') }}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Men')}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Women') }}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Women')}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={() => { this.headerNavigation('CategoriessubcategoriesWebPage', 'Kids') }}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Kids')}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={() => this.headerNavigation('BuyerStore')}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Stores')}
                                    </Typography>
                                    <Typography
                                        className="link"
                                        onClick={this.handleHeaderButtonClick}
                                        style={{ fontSize: '14px' }}
                                    >
                                        {this.translate('Stylist')}
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        placeholder={this.translate('Search')}
                                        className="searchBoxTab"
                                        dir={i18n.dir()}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={search} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        style={{
                                            width: '179%',
                                            marginTop: '10px',
                                            borderRadius: '20px',
                                            padding: '8px 15px',
                                            marginLeft: "-8rem"
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={4}
                                    lg={4}
                                    md={5}
                                    sm={4}
                                    xs={4}
                                    className="profileSection"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        // gap: '10px',
                                    }}
                                >
                                    {!this.state.searchBox && (
                                        <TextField
                                            variant="outlined"
                                            placeholder={this.translate('Search')}
                                            className="searchBox"
                                            dir={i18n.dir()}
                                            onClick={() => this.headerNavigation('ProductSearch')}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={search} className="searchBoxIcon" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            style={{
                                                display: 'flex',
                                                maxWidth: '170px',
                                                height: '40px',
                                                borderRadius: '2px',
                                            }}
                                        />
                                    )}
                                    {this.state.showNotification && (
                                        <AppContext.Consumer>
                                            {(context: any) => (
                                                <CustomBadge showZero onClick={() => { context.closeIndicator(), this.headerRedirect('Notifications') }}>
                                                    <img style={{cursor: "pointer"}} height="68px" src={!!context.newNoti ? notification : notificationRead} alt="notifications" />
                                                </CustomBadge>
                                            )}
                                        </AppContext.Consumer>
                                    )}
                                    <CustomBadge badgeContent={this.state.numberOrder} showZero onClick={() => this.headerRedirect('ProductdetailsWeb')}>
                                        <img src={shopping} alt="shopping" style={{cursor: "pointer"}} />
                                    </CustomBadge>
                                    <div>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                            <Box
                                                aria-controls="simple-menu"
                                                className="profileBox"
                                                aria-haspopup="true"
                                                onClick={this.handleClick}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <IconButton>
                                                    <AccountCircleOutlinedIcon style={{ color: '#375280' }} />
                                                    <ExpandMoreOutlinedIcon style={{ color: '#375280' }} />
                                                </IconButton>
                                                <Custommenu
                                                    style={{ marginTop: '50px' }}
                                                    id="simple-menu"
                                                    keepMounted
                                                    anchorEl={this.state.anchorEl}
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={this.handleClose}
                                                    dir={i18n.dir()}
                                                >
                                                    <Box style={{ padding: '5px 15px' }} dir={i18n.dir()}>
                                                        {this.state.auth === null ? (
                                                            <>
                                                                <MenuItem
                                                                    className="itemtext"
                                                                    dir={i18n.dir()}
                                                                    onClick={() => { this.headerRedirect('EmailAccountRegistration') }}
                                                                >
                                                                    <img
                                                                        src={usericon}
                                                                        style={{
                                                                            marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                            marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                        }}
                                                                    />
                                                                    Sign Up
                                                                </MenuItem>
                                                                <MenuItem
                                                                    className="itemtext"
                                                                    dir={i18n.dir()}
                                                                    onClick={() => { this.headerRedirect('EmailAccountLoginBlock') }}
                                                                >
                                                                    <img
                                                                        src={admin}
                                                                        style={{
                                                                            marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                            marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                        }}
                                                                    />
                                                                    Sign In
                                                                </MenuItem>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <MenuItem
                                                                    className="itemtext"
                                                                    dir={i18n.dir()}
                                                                    onClick={() => { this.headerRedirect('Customisableuserprofiles2') }}
                                                                >
                                                                    <img
                                                                        src={usericon}
                                                                        style={{
                                                                            marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                            marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                        }}
                                                                    />
                                                                    My Account
                                                                </MenuItem>
                                                                <MenuItem
                                                                    className="itemtext"
                                                                    dir={i18n.dir()}
                                                                    onClick={() => { this.headerRedirect('Contactus') }}
                                                                >
                                                                    <img
                                                                        src={frame}
                                                                        style={{
                                                                            marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                            marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                        }}
                                                                    />
                                                                    Contact Us
                                                                </MenuItem>
                                                                <MenuItem
                                                                    className="itemtext"
                                                                    dir={i18n.dir()}
                                                                    onClick={() => { this.headerRedirect('Faqs') }}
                                                                >
                                                                    <img
                                                                        src={faq}
                                                                        style={{
                                                                            marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                            marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                        }}
                                                                    />
                                                                    FAQs
                                                                </MenuItem>
                                                                <MenuItem
                                                                    className="itemtextlogout"
                                                                    dir={i18n.dir()}
                                                                    onClick={this.handleOpenLogoutModel}
                                                                >
                                                                    {this.state.auth === null ? "" : (
                                                                        <img
                                                                            src={logout}
                                                                            style={{
                                                                                marginRight: i18n.dir() === 'rtl' ? '0' : '10px',
                                                                                marginLeft: i18n.dir() === 'rtl' ? '10px' : '0',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {this.state.auth === null ? 'Sign In' : 'Logout'}
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Custommenu>
                                            </Box>
                                        </ClickAwayListener>
                                    </div>
                                    <StyledDrawer
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={this.state.open1}
                                        onClose={this.handleClose1}
                                        closeAfterTransition
                                        BackdropComponent={Backdrop}
                                        BackdropProps={{
                                            timeout: 500,
                                        }}
                                        dir={i18n.dir()}
                                    >
                                        <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: '320px', backgroundColor: 'white' }}>
                                            <div style={{ textAlign: 'center', padding: '10px 30px 30px 30px' }}>
                                                <Box
                                                    id="transition-modal-title"
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        width: '300px',
                                                        fontSize: '18px',
                                                        color: '#375280',
                                                        fontWeight: 900,
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    {this.state.auth === null ? "" : this.translate("Log Out")}
                                                </Box>
                                                <h2
                                                    id="transition-modal-title"
                                                    style={{
                                                        fontFamily: 'Lato',
                                                        width: '300px',
                                                        fontSize: '18px',
                                                        color: '#375280',
                                                        fontWeight: 600,
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    {this.state.auth === null ? this.translate("accessProfile") : this.translate("accessProfile")}
                                                </h2>
                                                <div
                                                    style={{
                                                        marginTop: '15px',
                                                        display: 'flex',
                                                        gap: '20px',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <StyledButton style={{ fontSize: '18px', lineHeight: '20px' }} onClick={() => this.logout()}>
                                                        {this.translate('Log Out')}
                                                    </StyledButton>
                                                    <StyledButton1 style={{ fontSize: '18px' }} onClick={this.handleClose1}>
                                                        {this.translate('Cancel')}
                                                    </StyledButton1>
                                                </div>
                                            </div>
                                        </Fade>
                                    </StyledDrawer>
                                </Grid>
                            </>
                        )}




                    </Grid>

                </StyledWrapper>
                {this.state.modalOpen && this.renderDialog()}
                {this.state.buyerModal && this.renderBuyerDialog()}
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const Custommenu = styled(Menu)({
    width: '260px',
    height: '395px',
    "& .MuiMenu-paper": {
        top: "46px !important"
    },
    "& .itemtext": {
        color: "#375280",
        padding: '10px',
        borderBottom: '1px solid #E2E8F0',
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
    },
    "& .itemtextlogout": {
        color: "#F87171",
        padding: '10px',
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
    }
});

const StyledWrapper = styled(Box)({
    "& .headerMainGrid": {
        borderBottom: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    },
    "& .topContent": {
        padding: "3px 10px 5px 10px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: "#334155",
    },
    "& .topContentText": {
        color: "#fff !important",
        fontFamily: "Lato , sans-serif;",
        cursor: "default",
        fontSize: "14px !important",
        fontStyle: "normal !important",
    },
    "& .logoSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: "20px",
    },
    "& .menu": {
        display: "none !important",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        margin: "5px 10px 5px 10px !important",
        cursor: "pointer",
    },
    "& .linkSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
    },
    "& .link": {
        display: "block !important",
        margin: "0 10px 0 10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 500,
        lineHeight: "18px !important"
    },
    "& .profileSection": {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "nowrap",
    },
    "& .menuItems": {
        display: "none !important",
        position: "absolute",
        padding: "10px",
        top: "103px",
        width: "150px",
        left: "0",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
        zIndex: 1000
    },
    "& .menuLink": {
        margin: "10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato, sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 500,
        lineHeight: "18px !important",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    "& .searchBox": {
        margin: "5px 10px !important",
        marginLeft: `${i18n.dir() === "ltr" ? "24px !important" : "5px"}`,
        display: "block !important",
    },
    "& .searchIcon": { display: "none !important" },
    "& .searchBoxTab": { display: "none !important" },
    "& .searchBoxIcon": {
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    },
    "& .cartIcon": {
        margin: "5px 10px 5px 10px",
        cursor: "pointer !important",
    },
    "& .profileBox": {
        margin: "5px 10px 5px 10px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        cursor: "pointer",
        fontWeight: 100,
    },
    "@media screen and (min-width: 300px) and (max-width: 600px)": {
        "& .link": { display: "none !important" },
        "& .searchBox": { display: "none !important" },
        "& .searchBoxTab": { display: "block !important" },

        "& .cartIcon": {
            margin: "5px",
        },
        "& .menu": { display: "block !important" },
        "& .menuItems": {
            display: "flex !important",
        },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    },
    "@media screen and (min-width: 600px) and (max-width: 959px)": {
        "& .link": { display: "none !important" },
        "& .searchBox": { display: "none !important" },
        "& .searchBoxTab": { display: "block !important" },

        "& .cartIcon": {
            margin: "5px",
        },
        "& .menu": { display: "block !important" },
        "& .menuItems": {
            display: "flex !important",
        },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    },
    "@media screen and (min-width: 0px) and (max-width: 599px)": {
        "& .link": { display: "none !important" },
        "& .topContent": {
            padding: "3px 5px 5px 5px !important",
        },
        "& .topContentText": { fontSize: "10px !important" },
        "& .": { display: "none !important" },
        "& .searchBoxTab": { display: "block !important" },
        "& .logoSection": { padding: "10px 5px 10px 5px" },
        "& .linkSection": { padding: "10px 5px 10px 5px" },
        "& .profileSection": { padding: "10px 5px 10px 5px" },
        "& .menu": { display: "block !important" },
        "& .menuItems": {
            display: "flex !important",
            top: "84px",
        },
        "& .cartIcon": {
            margin: "5px !important",
        },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    }
})

export const StyledDrawer = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    backgroundColor: "#d16262", color: '#fff',
    border: "1px solid #d16262",
    textTransform: "none",
    width: "150px",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "#d16262",
    },
}));

export const StyledButton1 = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#375280",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    '&:hover': {
        backgroundColor: "#fff"
    },
}));

export const StyledButtonSigein = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    backgroundColor: "#CCBEB1",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}));
export const CustomBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#cdc1b6',
        color: '#375280',
        fontSize: '10px',
        height: '16px',
        minWidth: '16px',
        padding: '0 4px',
        borderRadius: '50%',
        cursor: 'pointer'
    },
    cursor: "pointer"
}));
// Customizable Area End
